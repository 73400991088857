import {Card, CardContent, Typography} from "@mui/material";
import Loading from "../../components/Common/Loading";
import SimpleTable from "../../components/Common/Elements";
import {useGetEnvironmentsV1CMQuery} from "../../core/api/clockmaker-analytical-data-api";


const TechInfoCM = (props) => {

    const {
        data,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetEnvironmentsV1CMQuery(props.deviceId)

    let info = ""

    console.log('props')
    console.log(props)

    const simple_view_cols = ['model', 'name', 'os', 'currentVersion', 'systemMemory', 'graphicsMemory', 'resolution']


    console.log(typeof data)
    if (typeof data === "object") {
        let techData = []
        for (const key in data[0]) {
            if (props.isSimple && simple_view_cols.indexOf(key) == -1) continue;
            techData.push([key, data[0][key]])
        }
        info = <SimpleTable data={techData}/>
    } else {
        info = "no data"
    }

    return (
        <Loading
            isLoading={isLoading}
            isError={isError}
            error={error}
            isSuccess={isSuccess}
        >
            <Card id={"tech-info"}>
                <CardContent>
                    <Typography sx={{fontSize: 16}}>
                        Tech info
                    </Typography>
                </CardContent>
                {info}
            </Card>
        </Loading>
    )
}

export default TechInfoCM