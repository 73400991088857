import React, {useState} from 'react';
import {useParams} from "react-router-dom";

import {Card, CardContent, Tab, Tabs} from "@mui/material";

import ChargesList from "../chargesList";

import {TabContext, TabPanel} from "@mui/lab";


import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import DeviceCmChargerLogs from "../../ChargerLogs/cm/DeviceCmChargerLogs";
import {useGetChargesV2CMQuery} from "../../../core/api/clockmaker-charge-api";
import CmChargeBlock from "./cmChargeBlock";

dayjs.extend(utc); // extend Day.js with the UTC plugin


function Charger() {

    console.log("init charger")
    const {deviceId: deviceIdParam} = useParams()
    const [selectedItemList, setSelectedItemList] = useState([{
        id: Math.random(),
        type: null,
        chargeType: null,
        category: null,
        key: null,
        amount: null,
        date: null,
        customFields: null
    }]);

    const deviceId = deviceIdParam || ''

    let version = window.localStorage.getItem('isProd') == 'true' ? 'v1' : 'v2';

    const {
        data: chargesData,
        isLoading: chargesLoading,
        isSuccess: chargesSuccess,
        isError: chargesIsError,
        error: chargesError
    } = useGetChargesV2CMQuery({
        deviceId: deviceId,
        version: 'v1'
    })


    const [tabIndex, setTabIndex] = useState("1");

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };


    return (
        <>
            <Card sx={{mb: 2}}>
                <CardContent>
                    <CmChargeBlock
                        massCharge={true}
                        selectedItemList={selectedItemList}
                        setSelectedItemList={setSelectedItemList}
                    />

                </CardContent>
            </Card>

            {deviceId != '' && <TabContext value={tabIndex}>
                <Tabs value={tabIndex} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Charges" value="1"/>
                    <Tab label="Charger Logs" value="2"/>
                </Tabs>
                <TabPanel value="1">
                    <Card>
                        <ChargesList
                            deviceId={deviceId}
                            chargesData={chargesData}
                            chargesLoading={chargesLoading}
                            chargesSuccess={chargesSuccess}
                            chargesIsError={chargesIsError}
                            chargesError={chargesError}
                        />
                    </Card>
                </TabPanel>
                <TabPanel value="2">
                    <DeviceCmChargerLogs deviceId={deviceId}/>
                </TabPanel>
            </TabContext>}

        </>
    );

}

export default Charger;
