import React, {useEffect} from "react";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import chargeConstants from "../../ChargeManagement/constants";
import {Autocomplete} from "@mui/lab";

export default function SelectedItemBlock(props) {

    useEffect(() => {

        props.setIsChildChanged(!props.childChanged);
        updateBlockingChildren();

    }, [props.item.type, props.item.key, props.item.category, props.item.customFields, props.item.amount])


    function updateBlockingChildren() {
        let newBlockingChildrenList = [...props.someChildIsBlocking];
        newBlockingChildrenList[props.index] = someFieldBlocks();
        props.setSomeChildIsBlocking(newBlockingChildrenList);
    }

    const someFieldBlocks = () => {
        let selectedType = props.chargeTypes.filter(i => i.key === props.item.key);
        if (selectedType.length === 0) {
            return true;
        }
        if (selectedType.length > 0 && selectedType[0].key && !props.item.key || !props.item.type) {
            return true;
        }

        if (selectedType[0]?.consumable && !props.item.amount) {
            return true;
        }

        if (selectedType[0]?.customFields.length > 1) {
            for (let i in selectedType[0].customFields) {
                if (!props.item.customFields
                    || props.item.customFields[selectedType[0].customFields[i]['key']] === undefined
                    || props.item.customFields[selectedType[0].customFields[i]['key']] === "") {
                    return true;
                }
            }
        }

        return false;
    }

    function getUniqueItems(items) {
        return Array.from(new Set(items))
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={3} mt={1}>
                <FormGroup id={"cmform__charge-type"}>
                    <FormControl fullWidth>
                        <InputLabel>Type</InputLabel>
                        <Select
                            value={props.item.type}
                            label="Type"
                            onChange={(event) => props.handleChange(event.target.value, props.item, "type")}
                        >
                            {props.isSuccess && getUniqueItems(props.chargeTypes.map(i => i.type)).map(item => (
                                <MenuItem value={item}>{chargeConstants.CmChargeTypeOptions[item]}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                </FormGroup>
            </Grid>
            <Grid item xs={3} mt={1}>
                {props.item.type &&
                    <FormGroup id={"cmform__charge-type"}>
                        <FormControl fullWidth>
                            <InputLabel>Category</InputLabel>
                            <Select
                                value={props.item.category}
                                label="Category"
                                onChange={(event) => props.handleChange(event.target.value, props.item, "category")}
                            >
                                {getUniqueItems(props.chargeTypes.filter(i => i.type === props.item.type).map(i => i.category))
                                    .map(item => (<MenuItem value={item}>{item}</MenuItem>))}
                            </Select>
                        </FormControl>
                    </FormGroup>}
            </Grid>
            <Grid item xs={3} mt={1}>

                {props.item.category &&
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={props.chargeTypes.filter(i => i.category === props.item.category)}

                        fullWidth={true}
                        renderInput={(params) => <TextField {...params} label="Item"/>}
                        getOptionLabel={(option) => option.title} // отображает название опции
                        onChange={(event, value) => {
                            props.handleChange(value ? value.key : '', props.item, "key");
                        }}
                        value={props.chargeTypes.find(i => i.key === props.item.key) || null} // для отображения текущего значения
                    />
                }

            </Grid>
            <Grid item xs={3} mt={1}>
                {props.item.key
                    && props.item.type === chargeConstants.CmChargeTypeOptions.items.toLowerCase() &&
                    <FormGroup>
                        <FormControl fullWidth>
                            <TextField
                                label={props.chargeTypes.filter(i => i.key === props.item.key)[0]?.consumable ? "Amount" : "Duration"}
                                value={props.item.amount}
                                type={'number'}
                                onChange={(event) => {
                                    props.handleChange(event.target.value, props.item, "amount")
                                }}>
                            </TextField>
                        </FormControl>
                    </FormGroup>
                }
            </Grid>
            <Grid item xs={12} container spacing={2}>
                {props.item.key && (props.chargeTypes.filter(i => i.key === props.item.key)[0]?.customFields.map((field, customFieldIndex) => {
                        return (
                            <Grid item xs={6 / props.chargeTypes.filter(i => i.key === props.item.key)[0]?.customFields.length}>
                                <FormGroup>
                                    {field.type !== chargeConstants.CmChargeAdditionalFieldsTypes[1] ?
                                        <TextField
                                            type={field.type === chargeConstants.CmChargeAdditionalFieldsTypes[2] ? 'number' : ""}
                                            label={field.title}
                                            value={props.item['customFields']?.[customFieldIndex]?.key}
                                            onChange={(event) => {
                                                props.handleChangeCustomFields(field.key, event.target.value, props.item, customFieldIndex);
                                            }}
                                        >
                                        </TextField> :
                                        <FormControlLabel sx={{pl: 1}}
                                                          label={field.title}
                                                          control={
                                                              <Checkbox
                                                                  checked={String(props.item['customFields'] ? props.item['customFields'][field.key] : "") === 'true'}
                                                                  onChange={(event) => props.handleChangeCustomFields(field.key, event.target.checked, props.item, customFieldIndex)}
                                                              >
                                                              </Checkbox>
                                                          }
                                        />
                                    }
                                </FormGroup>
                            </Grid>

                        )
                    }
                ))}
            </Grid>
            <Grid item mb={1}>
                <Button
                    disabled={props.index === 0 && props.selectedItemList.length === 1}
                    variant="contained"
                    color="info"
                    onClick={() => props.handleRemoveItem(props.index)}>
                    Remove
                </Button>
            </Grid>

        </Grid>
    )
}