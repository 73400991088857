import React from 'react';
import {Link} from 'react-router-dom';

import CM_Unity from "./img/CM_Unity.jpg"
import FE from "./img/FE.jpg"
import PF from "./img/PF.jpg"
import SC from "./img/SC.jpg"
import ToH from "./img/ToH.png"
import TOH2 from "./img/TOH2.png"
import RT from "./img/RT.png"

import {Grid} from "@mui/material";
import {useSelector} from "react-redux";

export default function GamesList() {
    const state = useSelector((state) => state.data.UserSession)

    return (
        <>
            <div className="games-wrapper">
                <Grid
                    container
                    spacing={1}
                    margin={"auto"}
                    width={800}
                    sx={{display: "flex", flexWrap: "wrap"}}
                >
                    <Grid item sm={6}>
                        <Link to="/cm" className="btn choose-project px-0 py-0">
                            <div className="img-container">
                                <img
                                    src={CM_Unity}
                                    alt="Clockmaker Unity"
                                    className="img-content rounded mx-auto img-thumbnail"
                                />
                            </div>
                        </Link>
                    </Grid>
                    <Grid item sm={6}>
                        <Link to="/toh" className="btn choose-project px-0 py-0">
                            <div className="img-container">
                                <img
                                    src={TOH2}
                                    alt="Train of Hope"
                                    className="img-content rounded mx-auto img-thumbnail"
                                />
                            </div>
                        </Link>
                    </Grid>
                    <Grid item sm={4} hidden={true}>
                        <Link to="/fb" className="btn choose-project px-0 py-0">
                            <div className="img-container">
                                <img
                                    src={FE}
                                    alt="Funky Bay"
                                    className="img-content rounded mx-auto img-thumbnail"
                                />
                            </div>
                        </Link>
                    </Grid>
                    <Grid item sm={4} hidden={false}>
                        <Link to="/sc" className="btn choose-project px-0 py-0">
                            <div className="img-container gs">
                                <img
                                    src={SC}
                                    alt="Solitaire Cruise"
                                    className="img-content rounded mx-auto img-thumbnail"
                                />
                            </div>
                        </Link>
                    </Grid>
                    <Grid item sm={4}>
                        <Link to="/ba" className="btn choose-project px-0 py-0">
                            <div className="img-container gs">
                                <img
                                    src={PF}
                                    alt="Bermuda Adventures"
                                    className="img-content rounded mx-auto img-thumbnail"
                                />
                            </div>
                        </Link>
                    </Grid>
                    <Grid item sm={4}>
                        <Link to="/rt" className="btn choose-project px-0 py-0">
                            <div className="img-container gs">
                                <img
                                    src={RT}
                                    alt="Roger That"
                                    className="img-content rounded mx-auto img-thumbnail"
                                />
                            </div>
                        </Link>
                    </Grid>

                </Grid>
            </div>

        </>
    );

}

