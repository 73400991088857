import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';

import SimpleTable from "../../components/Common/Elements";

import {Card, CardContent, Grid, Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";


import Loading from "../../components/Common/Loading";
import {useGetStateV2CMQuery} from "../../core/api/cloclmaker-state-api";
import {
    countDisenchantedBuildings,
    formatDate, getDeviceIdAbsentWarning,
    getItemByName,
    getLastSession,
    HOUSES,
    paymentUserStatus,
    timestampToDate
} from "../../core/utils/constants";
import TechInfoCM from "../TechInfo/techInfoCM";

import { parse, stringify } from 'lossless-json'
import {useSelector} from "react-redux";
import UserSocialInfo from "../UserInfo/userSocialInfo";
import UserSocialInfoCM from "../UserInfo/userSocialInfoCM";
import {format} from "date-fns";


function DashboardCM() {

    const version = window.localStorage.getItem('isProd') == 'true' ? 'v2' : 'v2';
    const CommonData = useSelector((state) => state.data.CommonData)
    const deviceId = CommonData.deviceId
    const stateId = CommonData.stateId
    const deviceIdIsEmpty = deviceId == null || deviceId == '' || typeof deviceId == 'object';

    const {
        data: obj,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error
    } = useGetStateV2CMQuery({deviceId: deviceId, stateId: stateId, version: version})



    let panel1 = ""
    //
    // useEffect(() => {
    //     console.log(deviceIdIsEmpty)
    //     debugger
    // }, [CommonData])
    //
    // useEffect(() => {
    //     debugger
    // }, [])
    //
    // debugger

    const getFormattedDate = (obj, attr) => {
        if(!obj.hasOwnProperty(attr) || obj[attr] == undefined) {
            throw new Error("No session date");
        }
        if(!obj[attr].hasOwnProperty('_lastSessions')) {
            throw new Error("No session date");
        }
        let date = getLastSession(obj[attr]['_lastSessions'])
        return formatDate(date)
    }

    if (typeof obj === "object" && obj.hasOwnProperty('data')) {
        const data = obj.data
        if(data.hasOwnProperty('UserID')) {

            let source = {
                account_info: [],
                progress_info: []
            }


            let lastSession = 'n/a'
            try {
                lastSession = getFormattedDate(data, 'LastSessionsState')
            } catch (e) {
                try {
                    lastSession = getFormattedDate(data, 'SessionsState')
                } catch (e) {

                }
            }


            source = {
                account_info: [
                    ["Registration Date", format(data.RegDate*1000, "dd.MM.yyyy HH:mm:ss")],
                    ["Last session", lastSession],
                    ["Facebook id", data.FacebookID],
                    //["SocialID", data.SocialID],
                    ["User ID", data.UserID],
                    ["Username", data.hasOwnProperty('ProfileState') && data.ProfileState.hasOwnProperty("_nickname") ? data.ProfileState['_nickname'] : '-'],
                    ["Current version", data.CurrentClientVersion]
                ],
                progress_info: [
                    ["Next level", data.Match3Levels.NextLevelId],
                    //["Location", state.guidMap["Locations"][data.progress.locations.current]], //guid-Map
                    [
                        "Houses disenchanted",
                        `${countDisenchantedBuildings(data.Buildings.States)}/53`
                    ],
                    [
                        "Current house name",
                        HOUSES[Number.parseInt(data.Buildings.States[data.Buildings.States.length - 1].Id)]
                    ],
                    [
                        "Current house step",
                        data.Buildings.States[data.Buildings.States.length - 1].LevelNumber
                    ],
                    [
                        "Loop index",
                        data.Match3Levels.SagaLoopsFinished
                    ],
                    [
                        "Loop level",
                        data.Match3Levels.SagaLoopsLevelIndex
                    ]
                ],
                ingame_goods: [
                    [
                        "Ruby",
                        getItemByName(data.Items.States, "ruby").Count
                    ],
                    [
                        "Extra Lives",
                        data.LivesState._extraLives
                    ],
                    [
                        "Unlimited lives",
                        formatDate(timestampToDate(data.LivesState._infinityLivesTimeEnd))
                    ]
                ],
                payment_info: [
                    [
                        "Segment",
                        paymentUserStatus(data.PaymentsState.RealSpentSum)
                    ],
                    [
                        "Total",
                        Math.floor(data.PaymentsState.RealSpentSum)
                    ],
                    [
                        "Last payment date",
                        formatDate(timestampToDate(data.PaymentsState.RealSpentDate))
                    ]
                ],
                clan: [
                    ["Clan ID", data.ClansState !== undefined
                        ? data.ClansState.ClanId
                        : undefined],
                    ["Count reports", data.ClansState !== undefined
                        ? data.ClansState.ReportedChatMessages.length
                        : undefined]
                ],
                subscribe_info: [
                    [
                        "Current level",
                        data.hasOwnProperty('SubscriptionState') ? data.SubscriptionState.CurrentActiveLevel : ''
                    ]
                ]

            }

            let battlePass = data.OffersState._offers.filter(event => {
                return event["$type"] === "BPRStateData, Assembly-CSharp"
            });
            if(battlePass.length > 0) battlePass = battlePass[battlePass.length-1]
            else battlePass = false

            panel1 = <Grid container spacing={2}>
                <Grid item sm={4}>
                    <Card id={"account-info"}>
                        <CardContent>
                            <Typography sx={{fontSize: 16}}>
                                Account info
                            </Typography>
                        </CardContent>

                        <SimpleTable data={source.account_info}/>
                    </Card>

                </Grid>


                <Grid item sm={4}>
                    <Card>
                        <CardContent>
                            <Typography sx={{fontSize: 16}}>
                                Ingame goods
                            </Typography>
                        </CardContent>
                        <SimpleTable data={source.ingame_goods}/>

                    </Card>
                    {battlePass&&<Card>
                        <CardContent>
                            <Typography sx={{fontSize: 16}}>
                                Battle Pass
                            </Typography>
                        </CardContent>
                        <Table>
                            <TableBody>
                                    <TableRow>
                                        <TableCell>GoldPassPurchased</TableCell>
                                        <TableCell>{battlePass.GoldPassPurchased == true ? 'YES' : 'NO'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>PremiumPassPurchased</TableCell>
                                        <TableCell>{battlePass.PremiumPassPurchased == true ? 'YES' : 'NO'}</TableCell>
                                    </TableRow>
                            </TableBody>
                        </Table>
                    </Card>}
                </Grid>

                <Grid item sm={4}>

                    <TechInfoCM isSimple={true} deviceId={deviceId}/>
                </Grid>
                <Grid item sm={4}>
                    <Card>
                        <CardContent>
                            <Typography sx={{fontSize: 16}}>
                                Progress info
                            </Typography>
                        </CardContent>
                        <SimpleTable data={source.progress_info}/>
                    </Card>
                </Grid>

                <Grid item sm={4}>
                    <Card>
                        <CardContent>
                            <Typography sx={{fontSize: 16}}>
                                Payment info
                            </Typography>
                        </CardContent>
                        <SimpleTable data={source.payment_info}/>
                    </Card>
                </Grid>

                <Grid item sm={4}>
                    <Card>
                        <CardContent>
                            <Typography sx={{fontSize: 16}}>
                                Clan info
                            </Typography>
                        </CardContent>
                        <SimpleTable data={source.clan}/>
                    </Card>
                </Grid>
                <Grid item sm={4}>
                    <Card>
                        <CardContent>
                            <Typography sx={{fontSize: 16}}>
                                Subscribe info
                            </Typography>
                        </CardContent>
                        <SimpleTable data={source.subscribe_info}/>
                    </Card>
                </Grid>
                <Grid item sm={6}>
                    <UserSocialInfoCM deviceId={deviceId}/>
                </Grid>
            </Grid>






        } else if (typeof obj === "object" && obj.data.hasOwnProperty('error')) {
            panel1 = obj.data.error
        }
    } else {
        panel1 = 'unknown error'
    }

    return (
        <>
            {deviceIdIsEmpty ? getDeviceIdAbsentWarning() :
                <Loading
                    isLoading={isLoading||isFetching}
                    isError={isError}
                    error={error}
                >
                    <div>{panel1}</div>
                </Loading>}

        </>
    );

}

export default DashboardCM
