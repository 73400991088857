import React, {useEffect, useState} from "react";
import {Autocomplete, Box, Button, FormControl, FormGroup, Grid, TextField} from "@mui/material";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {useGetGuidMapQuery} from "../../../core/api/bermuda-charge-api";

export default function Guid2Block(props) {
    const [amount, setAmount] = useState(0)
    const [guid2, setGuid2] = useState(null)
    const [endTime, setEndTime] = useState(null)
    const [guid, setGuid] = useState(null)


    const {
        data: guidMap,
    } = useGetGuidMapQuery()

    useEffect(() => {
        let newValidateChildrenList = [...props.validateChildrenList]
        if (props.resetData) {
            setGuid(null);
            setGuid2(null);
        }
        newValidateChildrenList[props.index] = !checkChargeButtonDisable()
        props.setValidateChildrenList(newValidateChildrenList)

        // props.setChargeButtonDisable(checkChargeButtonDisable());
    }, [guid, guid2, props.selectedITemList[props.index]])

    const checkChargeButtonDisable = () => {
        return (((props.selectedITemList[props.index]["item"].guidMap !== "") && guid == null) ||
            ((props.selectedITemList[props.index]["item"].guidMap2 !== null) && guid2 == null))

        // for (let item in props.selectedITemList) {
        //     if (!props.selectedITemList[item]["item"].hasOwnProperty("value") ||
        //         ((props.selectedITemList[item]["item"].guidMap!=="") && guid==null ) ||
        //         ((props.selectedITemList[item]["item"].guidMap2 !== null) && guid2==null)) {
        //         return true;
        //     }
        // }
        // return false
    }

    const handleChangeAmount = (event) => {
        setAmount(event.target.value)
        let newItem = {...props.selectedITemList[props.index], "amount": event.target.value};
        let newSelectedItemList = [...props.selectedITemList];
        newSelectedItemList[props.index] = newItem;
        props.setSelectedItemList(newSelectedItemList);
    }
    const handleChangeItem2 = (event, values) => {
        props.setResetData(false)
        setGuid2(values)
        let newItem = {...props.selectedITemList[props.index], "guid2": values};
        let newSelectedItemList = [...props.selectedITemList];
        newSelectedItemList[props.index] = newItem;
        props.setSelectedItemList(newSelectedItemList);
    }

    function showAmount(selectedItem) {
        return selectedItem.hasOwnProperty("amount") && selectedItem.amount == true
    }

    function showEndtime(selectedItem) {
        return selectedItem.hasOwnProperty("endTime") && selectedItem.endTime == true
    }

    const handleChangeItem = (event, values) => {
        props.setResetData(false)
        setAmount(0)
        setGuid(values)

        let newItem = {...props.selectedITemList[props.index], "guid": values};
        let newSelectedItemList = [...props.selectedITemList];
        newItem.amount = '';
        newSelectedItemList[props.index] = newItem;
        props.setSelectedItemList(newSelectedItemList);
    }

    const handleAddItem = () => {
        let newSelectedItemList = [...props.selectedITemList];
        newSelectedItemList.push({"item": props.selectedItem});
        props.setSelectedItemList(newSelectedItemList)
    }

    const handleRemoveItem = () => {
        let newSelectedItemList = [...props.selectedITemList];
        newSelectedItemList.splice(props.index, 1);
        props.setSelectedItemList(newSelectedItemList)
        let newValidateChildrenList = [...props.validateChildrenList];
        newValidateChildrenList.splice(props.index, 1);
        props.setValidateChildrenList(newValidateChildrenList);
    }

    const handleSetTime = (value) => {
        setEndTime(value)

        let newItem = {...props.selectedITemList[props.index], "endTime": value};
        let newSelectedItemList = [...props.selectedITemList];
        newSelectedItemList[props.index] = newItem;
        props.setSelectedItemList(newSelectedItemList);
    }

    function showAddButton(selectedItem) {
        return selectedItem.guidMap !== null && selectedItem.guidMap !== ""
            || selectedItem.guidMap2 !== null && selectedItem.guidMap2 !== ""
    }

    return (
        <>
            {(guidMap && guidMap.hasOwnProperty(props.selectedItem.guidMap) && props.selectedItem.guidMap != null) &&
                <>
                    <FormGroup sx={{pb: 2, pt: 2}}>
                        <Autocomplete
                            freeSolo
                            disablePortal
                            id="combo-box-demo"
                            options={guidMap[props.selectedItem.guidMap]}
                            getOptionLabel={(item) => item.displayName || ""}
                            onChange={handleChangeItem}
                            value={props.selectedITemList[props.index].guid === undefined ? "" : props.selectedITemList[props.index].guid}
                            renderOption={(props, option) => (
                                <li {...props} key={option.guid}>
                                    {option.displayName}
                                </li>
                            )}
                            renderInput={(params) => <TextField
                                {...params}
                            />
                            }
                        />

                    </FormGroup>
                    {props.selectedITemList[props.index].guid != undefined && props.selectedITemList[props.index].item.guidMap=="Blueprints"&&props.selectedITemList[props.index].guid.hasOwnProperty('label')&&<>
                        <FormGroup sx={{pb:2}}>
                            <Grid container>
                                <Grid item sm={3} sx={{pr:1}}>
                                    <TextField fullWidth={true} disabled={true} value={props.selectedITemList[props.index].guid.label} label={"label"}></TextField>

                                </Grid>
                                <Grid item sm={2} sx={{pr:1}}>
                                    <TextField fullWidth={true} disabled={true} value={props.selectedITemList[props.index].guid.x} label={"sizeX"}></TextField>

                                </Grid>
                                <Grid item sm={2}>
                                    <TextField fullWidth={true} disabled={true} value={props.selectedITemList[props.index].guid.y} label={"sizeY"}></TextField>

                                </Grid>
                            </Grid>
                        </FormGroup>

                    </>}
                </>
            }

            {props.selectedItem && props.selectedItem.guidMap2 != null &&
                <FormGroup sx={{pb: 2}}>
                    <Autocomplete

                        disablePortal
                        id="combo-box-demo"
                        options={guidMap[props.selectedItem.guidMap2]}
                        getOptionLabel={(item) => item.displayName}
                        value={guid2}
                        onChange={handleChangeItem2}
                        renderOption={(props, option) => (
                            <li {...props} key={option.guid}>
                                {option.displayName}
                            </li>
                        )}
                        renderInput={(params) => <TextField {...params} label={props.selectedItem.guidMap2}/>}
                    />

                </FormGroup>
            }


            <Grid container spacing={2} sx={{pb: 1}}>
                {showAmount(props.selectedItem) && <Grid item xs={6}>
                    <FormGroup sx={{pb: 2}}>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                value={props.selectedITemList[props.index].amount}
                                label={props.selectedItem.amountAttribute || "Amount"}
                                onChange={handleChangeAmount}
                                type="number"
                            />

                        </FormControl>
                    </FormGroup>

                </Grid>}
                {showEndtime(props.selectedItem) && <Grid item xs={6}>
                    <FormGroup sx={{pb: 2}}>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                label="Select Date and Time"
                                inputVariant="outlined"
                                value={endTime}
                                ampm={false}
                                onChange={(newValue) => handleSetTime(newValue)}
                                format={'DD/MM/YYYY HH:mm'}

                            />
                        </LocalizationProvider>

                    </FormGroup>
                </Grid>}
                <Grid container spacing={1} alignItems="start">
                    {(props.index !== 0 || (props.index === 0 && props.selectedITemList.length > 1)) && <Grid item>
                        <Box marginLeft={2}>
                            <Button
                                variant="contained"
                                color="info"
                                onClick={handleRemoveItem}
                            >
                                Remove
                            </Button>
                        </Box>
                    </Grid>}
                    {props.index === props.selectedITemList.length - 1 && showAddButton(props.selectedItem) &&
                        <Grid item>
                            <Box marginLeft={2}>
                                <Button
                                    disabled={props.addButtonDisabled}
                                    variant="contained"
                                    color="info"
                                    onClick={handleAddItem}
                                >
                                    Add
                                </Button>
                            </Box>
                        </Grid>}
                </Grid>
            </Grid>


        </>
    )
}